import React from "react"
import { getCourseNavigation } from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"

import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import Paragraph from "../../../../components/paragraph"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/manuskript-erforschen" />
      }
      navigation={navigation}
    >
      <Seo title="Die Entstehungsgeschichte" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 2
            </Heading>
            <Heading as="h2" level={2}>
              Die Entstehungsgeschichte
            </Heading>
          </Stack>
          <Paragraph>
            ›Hälfte des Lebens‹ war das letzte Gedicht, das Hölderlin selbst für
            den Druck vorbereitet und veröffentlicht hat. Gemeinsam mit 6
            weiteren Gedichten von Hölderlin erschien es in Friedrich Wilmans
            ›Taschenbuch für das Jahr 1805. Der Liebe und Freundschaft
            gewidmet‹. In diesem Kapitel gehst du der Entstehungsgeschichte nach
            und verfolgst das Gedicht vom ersten Entwurf bis zur Publikation und
            seiner Aufnahme in der Literaturkritik jener Zeit.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
